<template>
  <a-modal
    dialogClass="close_abtest_modal"
    v-model="closeAbtestVisible"
    title="结束A/B测试"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    :width="600"
  >
    <div class="close_abtest_wrapper">
      <a-alert
        message="请选择您认为最优的测试分组，关闭测试后，您将使用所选测试分组的配置，流量将全部分配到该测试组，且该操作不可撤销。"
        banner
      />
      <a-form-model ref="ruleForm" :model="endquery" :rules="rules" v-bind="layout">
        <a-form-model-item class="form_item" label="测试应用">
          <span>{{ appName }}</span>
        </a-form-model-item>
        <a-form-model-item class="form_item" label="测试广告位">
          <span>{{ placeName }}</span>
        </a-form-model-item>
        <a-form-model-item class="form_item" label="测试流量分组">
          <span>{{ groupName }}</span>
        </a-form-model-item>
        <a-form-model-item class="form_item" label="A/B测试名">
          <span>{{ groupInfo.abTests[0].testName }}</span>
        </a-form-model-item>
        <a-form-model-item
          class="form_item"
          label="A/B分组名"
          prop="testId"
          :rules="[{ required: true, message: '请选择测试分组', trigger: 'change' }]"
        >
          <a-select placeholder="请选择最优测试分组保存" v-model="endquery.testId" style="width: 220px">
            <a-select-option v-for="item in testList" :key="item.testId" :value="item.testId">
              {{ item.groupName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import { shutdownTest } from '@/api/aggregate'
export default {
  data () {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      endquery: { groupId: '', placeId: '', testId: undefined },
      rules: {
        // os: [{ required: true, message: '请选择系统平台', trigger: 'change' }],
        // name: [{ required: true, message: '请输入应用名称', trigger: 'blur' }],
        // bundle: [{ required: true, message: '请输入应用包名', trigger: 'blur' }]
      },
      testList: []
    }
  },
  mounted () {
    this.testList = this.groupInfo.abTests.filter((item) => item.testId !== 'default')
    this.endquery.groupId = this.query.groupId
    this.endquery.placeId = this.query.placeId
  },
  props: {
    query: {
      default: () => ({}),
      type: Object
    },
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    groupInfo: {
      default: () => ({}),
      type: Object
    },
    placeName: {
      default: '',
      type: String
    },
    appName: {
      default: '',
      type: String
    },
    groupName: {
      default: '',
      type: String
    }
  },
  watch: {
    groupInfo: {
      handler (val) {},
      deep: true,
      immediate: true
    }
  },
  computed: {
    closeAbtestVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    handleCancel () {
      this.closeAbtestVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const resp = await shutdownTest(this.endquery)
          if (resp.code === 200) {
            this.$message.success('操作成功')
            this.$emit('handleEndAbtest')
            this.closeAbtestVisible = false
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.form_item {
  margin-bottom: 10px;
}
</style>
<style lang="less">
.close_abtest_modal .ant-modal-body {
  background-color: #eee;
  .close_abtest_wrapper {
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    padding: 10px;
  }
}
</style>
